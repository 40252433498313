import { CompanyLookupDto } from './../../models/all.models';
import { Router } from '@angular/router';
import { UserRegistrationModel } from './../../shared/user.models';
import { finalize } from 'rxjs/operators';
import { UserService } from './../../shared/user.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  companyDetails!: UntypedFormGroup;
  isCompanyLookupLoading = false;
  showExistingUserName = false;
  emailResendLoading = false;
  registrationIsLoading = false;
  dataRequiresCorrection = false;
  emailResendSuccessful = {called: false, success:false};
  companyLookupCallResult = {called: false, success:false};
  emailValdiationCallResult = {called: false, success:false};
  step = 1;
  registrationForm: UntypedFormGroup;
  emailConfirmationForm: UntypedFormGroup;
  emailValidationLoading = false;
  errors = [];
  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private el: ElementRef
  ) {
    this.userService = userService;
    this.createRegistrationFrom();
    this.createConfirmationForm();
  }

  ngOnInit() {

  }

  searchCompany() {
    if(!this.companyDetails.invalid) {
      this.isCompanyLookupLoading = true;
      this.userService.lookupCompany(this.companyDetails.controls['registrationNumber'].value)
        .pipe(finalize(() => {
          this.isCompanyLookupLoading = false;
        }))
        .subscribe(data => {
          if (data != null) {
            this.setLookupCallResultStatus(true);
            this.mapCompanyDtoToRegistrationFormValues(data);
            this.next();
          } else {
            this.setLookupCallResultStatus(false);
            this.registrationForm.controls['companyRegistrationNumber'].setValue(this.companyDetails.controls['registrationNumber'].value);    
          }
        },
        err => {
          this.setLookupCallResultStatus(false);
          this.registrationForm.controls['companyRegistrationNumber'].setValue(this.companyDetails.controls['registrationNumber'].value);
        });
    } else {
      this.companyDetails.markAllAsTouched();
    }
  }


  next(){
    switch(this.step) {
      case 1:
        this.handleFirstStep();
        break;
      case 2:
        this.step++;
        break;
      default:
    }
  }

  handleFirstStep()
  {
    if (this.companyLookupCallResult.called && this.companyLookupCallResult.success) {
      this.step++;
      return;
    }

    if (this.CheckCompanyRegistrationValidation()) {
      this.step++;
      window.scroll({
        top: 50,
        behavior: "smooth"
      });
    } 
    else {
      this.registrationForm.controls['companyName'].markAsTouched();
      this.registrationForm.controls['companyRegistrationCountry'].markAsTouched();
      this.registrationForm.controls['companyLegalAddress'].markAsTouched();
      this.registrationForm.controls['companyRegistrationNumber'].markAsTouched();
      this.registrationForm.controls['companyVatNumber'].markAsTouched();
      this.findFirstInvalid();
    }
  }

  CheckCompanyRegistrationValidation(): boolean {
    return this.registrationForm.controls['companyName'].valid &&
    this.registrationForm.controls['companyRegistrationCountry'].valid &&
    this.registrationForm.controls['companyLegalAddress'].valid &&
    this.registrationForm.controls['companyRegistrationNumber'].valid &&
    this.registrationForm.controls['companyVatNumber'].valid;
  }

  previous(){
    this.step--
  }

  dataCorrection() {
    this.step = 1;
    this.dataRequiresCorrection = true
  }

  register() {
    if (!this.registrationForm.valid) {
      this.registrationForm.markAllAsTouched();
      this.findFirstInvalid();
      return;
    }

    this.registrationIsLoading = true;
    this.errors = [];
    const value = new UserRegistrationModel(this.registrationForm.value);
    this.userService.register(value)
    .pipe(finalize(() => this.registrationIsLoading = false))
      .subscribe(data => {
          this.userService.loginFromToken(data)
          localStorage.setItem('token', data.token);
          this.userService.emitSameUserValue();
          this.next();
      }
      , err => {
          if(err?.error && Array.isArray(err?.error) && err?.error.find(x => x.code === 'DuplicateUserName')) {
            this.showExistingUserName = true;
            this.findFirstInvalid();
          } else if(err?.error && Array.isArray(err?.error)){
            this.errors = err?.error;
          }
          else {
            this.errors = err?.errors ?? [{description: "Servera kļūda lūdzu mēģiniet vēlāk."}];
          }
        }
      );
  }
    
 private mapCompanyDtoToRegistrationFormValues(data: CompanyLookupDto) : void {
  this.registrationForm.controls['companyName'].setValue(data.name);
  this.registrationForm.controls['companyRegistrationCountry'].setValue(data.registrationCountry);
  this.registrationForm.controls['companyLegalAddress'].setValue(data.legalAddress);
  this.registrationForm.controls['companyRegistrationNumber'].setValue(data.registrationNumber);
  this.registrationForm.controls['companyVatNumber'].setValue(data.vatNumber);
 }

  private createRegistrationFrom() {
    this.registrationForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      surname: new UntypedFormControl('', Validators.required),
      authorityType: new UntypedFormControl('', Validators.required),

      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      passwordRepeat: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),

      phoneNumber: new UntypedFormControl('', Validators.required),

      companyRegistrationCountry: new UntypedFormControl('', Validators.required),
      companyRegistrationNumber: new UntypedFormControl('', Validators.required),
      companyLegalAddress: new UntypedFormControl('', Validators.required),
      companyVatNumber: new UntypedFormControl(''),
      companyName: new UntypedFormControl('', Validators.required),
      authorityTypeOther: new UntypedFormControl('')
    },
    { validators: [this.checkPasswords, this.checkNumber] }
    );

    this.companyDetails = this.formBuilder.group({
      registrationNumber: ['', [Validators.required, Validators.minLength(11)]]
    });
  }

  resendEmail() {
    this.emailResendLoading = true;
    this.userService.resendConfirmationEmail()
      .pipe(
        finalize(() => {
          this.emailResendLoading = false;
          this.emailResendSuccessful.called = true;
        })
      )
      .subscribe(data => {
        this.emailResendSuccessful.success = data;
      }
      ,err => {
        this.emailResendSuccessful.success = false;
      })
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password').value;
    let confirmPass = group.get('passwordRepeat').value
    let passContro = false;
    if(this.registrationForm) {
      passContro = this.registrationForm.controls['password'] && this.registrationForm.controls['passwordRepeat'].dirty;
    }

    if (passContro) {
      if(pass !== confirmPass) {
        this.registrationForm.controls['password'].setErrors({ notSame: true });
        return { notSame: true }
      }
      else {
        this.registrationForm.controls['password'].setErrors(null);
      }
    }
       
    return null;    
  }

    checkNumber: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    this.showExistingUserName = false;
    let phone = group.get('phoneNumber').value;
  
    // if(this.registrationForm) {
    //   if(this.registrationForm.controls['phoneNumber'] && this.registrationForm.controls['phoneNumber'].dirty)
    //   {
    //       let startsWith = phone[0] === '2' || phone[0] === '6';
    //       let lenght = phone.length === 8;

    //       if(!startsWith || !lenght) {
    //         this.registrationForm.controls['phoneNumber'].setErrors({ phone: true });
    //         return { phone: true }
    //       } else {
    //         this.registrationForm.controls['phoneNumber'].setErrors(null);
    //       }
    //   }
    // }

    return null;    
  }

private createConfirmationForm() {
  this.emailConfirmationForm = new UntypedFormGroup({
    emailVerificationCode: new UntypedFormControl('', Validators.required),
  });
}

validateEmail() {
  this.emailValidationLoading = true;
  this.userService.confirmEmailAddress(this.emailConfirmationForm.value)
    .pipe(
      finalize(() => {
        this.emailValdiationCallResult.called = true;
        this.emailValidationLoading = false;
      })
    )
    .subscribe(success => {
      this.emailValdiationCallResult.success = success;
      if (success) {
        this.router.navigate(['cabinet/contract/']);
      }
    }    
  )
}

  findFirstInvalid() {
    setTimeout(() => {
      const firstInvalidControl: HTMLInputElement = this.el.nativeElement.querySelector(
        ".is-invalid"
      );
      
      firstInvalidControl.select();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl) - 30,
        left: 0,
        behavior: "smooth"
      });
    }, 300);
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  private setLookupCallResultStatus(success: boolean): void {
    this.companyLookupCallResult.called = true;
    this.companyLookupCallResult.success = success;
  }
}
