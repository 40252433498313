import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PropertyService } from '../property/property.service';

@Component({
  selector: 'app-view-bids-modal',
  templateUrl: './view-bids-modal.component.html',
  styleUrls: ['./view-bids-modal.component.scss']
})
export class ViewBidsModalComponent implements OnInit {
  isLoading: boolean = true;
  id: number;
  data: any;
  constructor(
    private propertyService: PropertyService,
    private modalRef: BsModalRef
    ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.propertyService.GetAllPropertyAuctions(this.id)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(x => {
        this.data = x
        console.log(this.data);
      
      }
      );
  }


  closeModal() {
    this.modalRef.hide();
  }
  
}
