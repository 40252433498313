import { SingleSignableContractComponent } from './signable-contracts/single-signable-contract/single-signable-contract.component';
import { SignableContractsComponent } from './signable-contracts/signable-contracts.component';
import { TreeTrypeComponent } from './tree-type/tree-trype/tree-trype.component';
import { RegionsComponent } from './region-modals/new-region-modal/Regions/Regions.component';
import { UpdateTranslationComponent } from './translations/update-translation/update-translation.component';
import { TranslationsComponent } from './translations/translations.component';
import { CountiesComponent } from './county-modals/counties/counties.component';
import { CountyEditComponent } from './county-modals/county-edit/county-edit.component';
import { ViewPropertiesComponent } from './property/view-properties/view-properties.component';
import { ViewUserComponent } from './roles/view-user/view-user.component';
import { AdminService } from './admin.service';
import { ViewAllUsersComponent } from './roles/view-all-users/view-all-users.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NewPropertyExecTypeModal } from './property-execution-type/new-region-modal/new-property-exec-type-modal.component';
import { NewCountyModalComponent } from './county-modals/new-region-modal/new-county-modal.component';
import { PropertyService } from './property/property.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewRegionModalComponent } from './region-modals/new-region-modal/new-region-modal.component';
import { AddPropertyComponent } from './property/add-property/add-property.component';
import { NgModule, createComponent } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill'
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthGuard } from '../auth/auth.guard';
import { EditPropertyComponent } from './property/edit-property/edit-property.component';
import { CreateComponent } from './posts/create/create.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AllArticlesComponent } from './posts/all-articles/all-articles.component';
import { ArticleService } from './posts/article.service';
import { ImageService } from './Image.service';
import { RegionEditComponent } from './region-modals/regiom-edit/region-edit.component';
import { ViewBidsModalComponent } from './view-bids-modal/view-bids-modal.component';


@NgModule({
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        QuillModule.forRoot({
            modules: {
              syntax: true
            }
          }),
        IonicModule.forRoot(),
        RouterModule.forRoot([
            { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { permittedRoles: ['CanAccessAdminPanel'] },
                children: [
                    { path: 'property', component: AddPropertyComponent },
                    { path: 'property/:id', component: EditPropertyComponent },
                    { path: 'county/:id', component: CountyEditComponent },
                    { path: 'region/:id', component: RegionEditComponent },
                    { path: 'county', component: CountiesComponent },
                    { path: 'users', component: ViewAllUsersComponent },
                    { path: 'regions', component: RegionsComponent },
                    { path: 'user', component: ViewUserComponent },
                    { path: 'properties', component: ViewPropertiesComponent },
                    { path: 'tree-types', component: TreeTrypeComponent },
                    { path: 'translations', component: TranslationsComponent },
                    { path: 'signable-contracts', component: SignableContractsComponent },
                    { path: 'post/create', component: CreateComponent },
                    { path: 'post', component: AllArticlesComponent }
                ] },
        ]),
    ],
    declarations: [AdminComponent, AllArticlesComponent, CreateComponent, TreeTrypeComponent, RegionsComponent, UpdateTranslationComponent, TranslationsComponent, CountiesComponent, CountyEditComponent, RegionEditComponent, ViewUserComponent, ViewPropertiesComponent, ViewAllUsersComponent, AddPropertyComponent, EditPropertyComponent, NewRegionModalComponent, FileUploadComponent, NewCountyModalComponent, NewPropertyExecTypeModal, SingleSignableContractComponent, SignableContractsComponent, ViewBidsModalComponent],
    exports: [RouterModule],
    providers: [PropertyService, AdminService, ArticleService, ImageService]
})

export class AdminModule { }
