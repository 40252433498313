<div class="modal-header">
  <h4 class="modal-title pull-left">Likmes</h4>
  <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!isLoading; else loading">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th>#</th>
          <th>Laiks</th>
          <th>Solījums</th>
          <th>Info</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          *ngFor="let da of data; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ da.bidDate | date: 'dd.MM.yyyy HH:mm'}}</td>
          <td>{{ da.auctionPrice }}</td>
          <td>{{ da.bidderName }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>

<ng-template #loading>
  <div class="w-100 pt-2">
    <h4 class="text-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    </h4>
  </div>
</ng-template>