import { TreeType } from './../../models/all.models';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuctionType, County, Parish, Property, PropertyExecutionType, Region } from '../administration.models';
import { Property as PropertyDto } from '../../models/all.models';

@Injectable()
export class PropertyService {
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
    ) { }

    // REGIONS
    getAllRegions(): Observable<Region[]> {
        return this.http.get<Region[]>(this.baseUrl + 'Region')
    }

    getAllParishes(): Observable<Parish[]> {
        return this.http.get<Parish[]>(this.baseUrl + 'Parish')
    }

    GetAllAuctionTypes(): Observable<AuctionType[]> {
        return this.http.get<AuctionType[]>(this.baseUrl + 'AuctionType')
    }

    GetAllPropertyAuctions(id: number): Observable<AuctionType[]> {
        return this.http.get<AuctionType[]>(this.baseUrl + 'Auction/GetPropertyAuctionBids/' + id)
    }

    saveRegion(region: Region): Observable<Region> {
        return this.http.post<Region>(this.baseUrl + 'Region/create', region);
    }

    // COUNTIES
    getAllCounties(): Observable<County[]> {
        return this.http.get<County[]>(this.baseUrl + 'County')
    }

    getCountyById(id: number): Observable<County> {
        return this.http.get<County>(this.baseUrl + 'County/' + id)
    }

    getRegionById(id: number): Observable<Region> {
        return this.http.get<Region>(this.baseUrl + 'Region/' + id)
    }

    saveCounty(county: County): Observable<County> {
        return this.http.post<County>(this.baseUrl + 'County/create', county);
    }

    
    updateCounty(county: County): Observable<County> {
        return this.http.post<County>(this.baseUrl + 'County/update', county);
    }

    // EXECUTION TYPES
    getAllExecutionTypes(): Observable<PropertyExecutionType[]> {
        return this.http.get<PropertyExecutionType[]>(this.baseUrl + 'PropertyExecitionType')
    }

    saveExecutionType(execType: PropertyExecutionType): Observable<PropertyExecutionType> {
        return this.http.post<PropertyExecutionType>(this.baseUrl + 'PropertyExecitionType/create', execType);
    }

    saveProperty(property: Property): Observable<Property> {
        return this.http.post<Property>(this.baseUrl + 'Property/create', property)
    }

    getAllProperties(): Observable<PropertyDto[]> {
        return this.http.get<PropertyDto[]>(this.baseUrl + 'Property');
    }

    getPropertyById(id: number): Observable<Property> {
        return this.http.get<Property>(this.baseUrl + 'Property/' + id);
    }

    updateProperty(property: Property): Observable<Property> {
        return this.http.post<Property>(this.baseUrl + 'Property/update', property)
    }

    getAllTreeTrypes(): Observable<TreeType[]> {
        return this.http.get<TreeType[]>(this.baseUrl + 'TreeType');
    }

    getTreeTypeById(id: number): Observable<TreeType> {
        return this.http.get<TreeType>(this.baseUrl + 'TreeType/' + id);
    }

    updateTreeType(treetType: TreeType): Observable<TreeType> {
        return this.http.post<TreeType>(this.baseUrl + 'TreeType/Update', treetType)
    }

    SendNotifications(id: number): Observable<TreeType> {
        return this.http.post<TreeType>(this.baseUrl + 'Property/SendNotifications', { propertyId: id })    
    }
}
