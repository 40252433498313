<ng-container *ngIf="files?.length > 0">
  <div class="col-12 mb-2">
    <div class="alert alert-danger d-flex justify-content-between" *ngIf="!isUserLoggedId">
      <span class="icon-icon-alert mr-2"></span>
      <p class="mb-0">Pielikumi ir pieejami tikai reģistrētiem lietotājiem!
        <a class="cursor-pointer" (click)="openLoginModal()">Pieslēgties</a>
      </p>
    </div>
  </div>
  <div class="col-12" *ngFor="let doc of files">
    <button type="button" class="document-button" (click)="openDocument(doc.id, doc.fileName)">
      <div class="row">
        <div class="col-2 m-auto">
          <img class="icon-download" src="../../../../assets/images/icon-image/icon-doc.png" />
        </div>
        <div class="col-10 m-auto pl-1 pr-0">
          {{doc.fileName}}
        </div>
      </div>
    </button>
  </div>
</ng-container>