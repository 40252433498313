import { PropertyService } from './../property.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Property } from '../../../models/all.models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewBidsModalComponent } from '../../view-bids-modal/view-bids-modal.component';

@Component({
  selector: 'app-view-properties',
  templateUrl: './view-properties.component.html',
  styleUrls: ['./view-properties.component.css']
})
export class ViewPropertiesComponent implements OnInit {
  properties: Property[] = [];
  isLoading = false;
  modalRef?: BsModalRef;

  constructor(
    private propertyService: PropertyService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.propertyService.getAllProperties()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe( x => this.properties = x )
  }

  checkAuctions(id: number) {
    const initialState = {
      id: id, // Pass any data you want
    };
    
    this.modalRef = this.modalService.show(ViewBidsModalComponent, {
      initialState,
      class: 'forms-modal',
    });

    this.propertyService.GetAllPropertyAuctions(id)
      .subscribe( x => {
        console.log(x);
      }
      )
  }
}
