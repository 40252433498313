import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FileDto } from '../../../models/all.models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../../../user/login/login.component';

@Component({
  selector: 'app-property-documents',
  templateUrl: './property-documents.component.html',
  styleUrls: ['./property-documents.component.scss']
})
export class PropertyDocumentsComponent implements OnInit {

  @Input() files: FileDto[]
  @Input() isUserLoggedId = false;
  
  modalRef?: BsModalRef;
  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
  }

  openLoginModal(): void {
    this.modalRef = this.modalService.show(LoginComponent,
      { class: 'registration-modal' });
  }

  openDocument(id: number, filename: string) {
    if(!this.isUserLoggedId) {
      this.openLoginModal();
      return;
    }

    const download$ = this.http.get(this.baseUrl + "File/DownloadFile/" + id.toString(), { responseType: "arraybuffer", observe: "response" });
   
    download$.subscribe(response => {
      // Extract the content type and filename from the response headers
      const contentType = response.headers.get('Content-Type') || 'application/octet-stream';
      // Create a Blob with the response data
      const file = new Blob([response.body], { type: contentType });
  
      // Trigger a download
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // Clean up
      URL.revokeObjectURL(fileURL);
    }, error => {
      console.error("Error downloading the file", error);
    });
  }
}
