import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  articleForm: UntypedFormGroup;
  editorContent = '';
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.createForm();
  }
  saveArticle() {
    this.http.post('https://localhost:44384/api/Article', { content: this.editorContent }).subscribe(res => {

    });
  }
  
  base64String: string | null = null;

  onFileSelected(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.base64String = e.target.result;
        this.articleForm.controls['thumbnail'].setValue(this.base64String);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      this.base64String = null;
    }
  }

  submitForm() {
    if(this.articleForm.invalid) {
      this.articleForm.markAllAsTouched();
      return;
    }
    this.http.post('https://localhost:44384/api/Article',this.articleForm.value).subscribe(res => {
    })
    
  }

  private createForm(): void {
    this.articleForm = new UntypedFormGroup({
      thumbnail: new UntypedFormControl('', Validators.required),
      publicationDate: new UntypedFormControl('', Validators.required),
      shortDescription: new UntypedFormControl('', Validators.required),
      content: new UntypedFormControl('', Validators.required),
      title: new UntypedFormControl('', Validators.required),
    })
  }
}
