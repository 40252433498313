<div class="row mt-3">
  <div class="col-12">
    <div class="row">
      <div class="col-8"><h2>Īpašumu saraksts</h2></div>
      <div class="col-4 text-right">
        <a class="btn btn-primary" [routerLink]="['../property']">Pievienot jaunu izsoli</a>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Reģions</th>
          <th scope="col">Novads</th>
          <th scope="col">Beigu datums</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!isLoading; else loading">
          <tr *ngFor="let prop of properties">
            <td>{{prop.id}}</td>
            <td>{{prop.region.name}}</td>
            <td>{{prop.county.name}}</td>
            <td>{{prop.auctionEnds}}</td>
            <td>{{prop.isEnabled ? 'Aktīvs' : 'Izslēgts'}}</td>
            <td>
              
              <a [routerLink]="'/admin/property/' + prop.id">Pārvaldīt</a>
              <button type="button" (click)="checkAuctions(prop.id)">Skatīt solījumus</button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<ng-template #loading>
  <tr>
    <td>
      <h4 class="text-center">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </h4>
  </td>
  </tr>
</ng-template>